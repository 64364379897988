<template>
  <div>
    <page-header />
    <main class="main-content">
      <div class="contents">
        <div class="crm mb-25">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </main>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    components: { PageHeader, PageFooter },
};
</script>

<style lang="scss" scoped>
</style>