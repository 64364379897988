<template>
  <div class="dropdown-custom">
    <a href="javascript:;" class="nav-item-toggle">
      <img src="img/setting.png" alt="setting" />
    </a>
    <div class="dropdown-parent-wrapper">
      <div class="dropdown-wrapper dropdown-wrapper--large">
        <ul class="list-settings">
          <li class="d-flex">
            <div class="me-3">
              <img src="img/mail.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">All Features</a>
              </h6>
              <p>Introducing Increment subscriptions</p>
            </div>
          </li>
          <li class="d-flex">
            <div class="me-3">
              <img src="img/color-palette.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">Themes</a>
              </h6>
              <p>Third party themes that are compatible</p>
            </div>
          </li>
          <li class="d-flex">
            <div class="me-3">
              <img src="img/home.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">Payments</a>
              </h6>
              <p>We handle billions of dollars</p>
            </div>
          </li>
          <li class="d-flex">
            <div class="me-3">
              <img src="img/video-camera.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">Design Mockups</a>
              </h6>
              <p>Share planning visuals with clients</p>
            </div>
          </li>
          <li class="d-flex">
            <div class="me-3">
              <img src="img/document.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">Content Planner</a>
              </h6>
              <p>Centralize content gethering and editing</p>
            </div>
          </li>
          <li class="d-flex">
            <div class="me-3">
              <img src="img/microphone.png" alt="" />
            </div>
            <div class="flex-grow-1">
              <h6>
                <a href="" class="stretched-link">Diagram Maker</a>
              </h6>
              <p>Plan user flows & test scenarios</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>