<template>
  <li>
    <router-link :to="menu.MenuLink" v-slot="{ navigate }">
      <a
        :href="`#${menu.MenuLink}`"
        @click="navigate"
        :style="menu.MenuStyle"
      >
        <i :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
        <span class="menu-text"> {{ menu.MenuText }} </span>
      </a>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>