<template>
  <div>
    <demo-mode/>
    <div id="app">
      <v-app>
        <v-main>
          <component :is="layout">
            <router-view />
          </component>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
import DemoMode from "@/DemoMode.vue";
const default_layout = "default";
export default {
  name: "app",
  components: { DemoMode },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style></style>