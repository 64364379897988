<template>
  <header class="header-top">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-left">
        <v-img contain width="175" src="img/logo-dark.png" alt="" /> &nbsp;
        <top-menu />
      </div>
      <v-spacer />
      <!-- ends: navbar-left -->
      <div class="navbar-right">
        <ul class="navbar-right__menu">
          <!-- <li class="nav-message">
            <nav-message />
          </li> -->
          <!-- ends: nav-message -->
          <!-- <li class="nav-notification">
            <nav-notification/>
          </li> -->
          <!-- ends: .nav-notification -->
          <!-- <li class="nav-settings">
            <nav-settings/>
          </li> -->
          <!-- ends: .nav-support -->
          <!-- <li class="nav-flag-select">
            <nav-flag/>
          </li> -->
          <!-- ends: .nav-flag-select -->
          <li class="nav-author">
            <user-menu />
          </li>
          <li>
            <v-btn class="dropdown-item" @click.prevent="logoutUser" variant="text">
              <v-icon left> mdi-logout </v-icon>
              Logout
            </v-btn>
          </li>
          <!-- ends: .nav-author -->
        </ul>
        <!-- ends: .navbar-right__menu -->
        <mobile-menu />
      </div>
      <!-- ends: .navbar-right -->
    </nav>
  </header>
</template>

<script>
import TopMenu from "@/components/TopMenu.vue";
import NavMessage from "@/components/NavMessage.vue";
import NavNotification from "@/components/NavNotification.vue";
import NavSettings from "@/components/NavSettings.vue";
import NavFlag from "@/components/NavFlag.vue";
import UserMenu from "@/components/UserMenu.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import common from "@/Common.vue";

export default {
  mixins: [common],
  components: {
    TopMenu,
    NavMessage,
    NavNotification,
    NavSettings,
    NavFlag,
    UserMenu,
    MobileMenu,
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>