import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

const app = createApp(App)
app.config.globalProperties.productionTip = false

// For Custom Layout components
import DefaultLayout from "@/layouts/DefaultLayout.vue";
app.component("default-layout", DefaultLayout);

import BlankLayout from "@/layouts/BlankLayout.vue";
app.component("blank-layout", BlankLayout);

import DemoMode from "@/DemoMode.vue";
app.component("demo-mode", DemoMode);

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
app.config.globalProperties.$toast = toast

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
app.use(VueSweetalert2);


import axios from 'axios'
app.config.globalProperties.$http = axios
app.config.globalProperties.CancelToken = axios.CancelToken;

import VueCookies from 'vue3-cookies'
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: false,
  sameSite: "None"
});

app
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
