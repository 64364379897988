<template>
  <div>
    <div class="main-content">
      <div class="admin">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-xxl-3 col-xl-4 col-md-6 col-sm-8">
              <div class="edit-profile">
                <slot />
              </div>
              <!-- End: .edit-profile -->
            </div>
            <!-- End: .col-xl-5 -->
          </div>
        </div>
      </div>
      <page-footer />
    </div>
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter.vue";
export default {
  components: { PageFooter },
};
</script>

<style lang="scss" scoped>
</style>