<template>
  <div
    class="collapse navbar-collapse hexadash-top-menu position-relative"
    id="navbarNav"
  >
    <header-menu-lists />
  </div>
</template>

<script>
import HeaderMenuLists from "@/components/HeaderMenuLists.vue";
export default {
  components: { HeaderMenuLists },
};
</script>

<style lang="scss" scoped>
</style>