<template>
  <footer class="footer-wrapper">
    <div class="footer-wrapper__inside">
      <div class="container-fluid">
        <v-row wrap>
          <v-col align="center" cols="12" md="4">
            <div class="text-dark order-2 order-md-1">
              <span class="text-muted font-weight-bold mr-2"
                >{{ CurrentYear }} ©</span
              >
              <a
                href="https://jciindia.in"
                target="_blank"
                class="text-dark-75 text-hover-primary"
                >JCI India</a
              >
            </div>
          </v-col>
          <v-col align="center" cols="12" md="4">
            <span class="text-muted font-weight-bold mr-2">Powered by </span>
            <a
              href="http://vaagai.org.in"
              target="_blank"
              class="text-dark-75 text-hover-primary"
              >Vaagai Tecknowledge</a
            >
          </v-col>
          <v-col align="center" cols="12" md="4">
            App version {{ AppVersion }}
          </v-col>
          <v-col align="center" cols="12" md="12">
            {{ VersionInfo }}
          </v-col>
        </v-row>
      </div>
    </div>
  </footer>
</template>

<script>
import { version } from "/package.json";
import common from "@/Common.vue";

export default {
  mixins: [common],
  data() {
    return {
      AppVersion: version,
      VersionInfo: "",
      CurrentYear: new Date().getFullYear(),
    };
  },
  beforeMount() {
    console.log("beforeMount called");
    var url2 = "api/pages/version-info";
    var condition2 = {
      UserInterface: 7,
    };
    this.getPageDetails("VersionInfo", "", url2, condition2);
  },
};
</script>

<style lang="scss" scoped>
</style>