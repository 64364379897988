import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: "blank" },
    component: () => import("@/views/LoginPage.vue")
  },
  {
    path: '/about',
    name: 'about',
    component: () => import("@/views/AboutView.vue")
  },
  {
    path: '/home',
    name: 'home',
    component: () => import("@/views/HomeView.vue")
  },
  {
    path: '/table-members',
    name: 'Table Members',
    component: () => import("@/views/jcom_table_members/JcomTableMembersList.vue")
  },
  {
    path: '/jcom-performance',
    name: 'Jcom Performance',
    component: () => import("@/views/jcom-performance/JcomPerformanceReport.vue")
  },
  {
    path: '/jcom-attendance',
    name: 'Jcom Attendance',
    component: () => import("@/views/jcom_attendance_report/JcomAttendanceReport.vue")
  },
  {
    path: '/jcom-growth',
    name: 'Jcom Growth',
    component: () => import("@/views/jcom_attendance_report/JcomGrowthReport.vue")
  },
  {
    path: '/jcom-mytable-members',
    name: 'Jcom Table member card',
    component: () => import("@/views/jcom_table_members/MyTableMembersListCard.vue")
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import("@/views/dashboard/Dashboard.vue")
  },
  {
    path: '/jcom-connect-list',
    name: 'Jcom Connect List',
    component: () => import("@/views/jcom-connect/JcomConnectList.vue")
  },
  {
    path: '/jcom-g-note-list',
    name: 'Jcom G-note List',
    component: () => import("@/views/jcom-gnote/JcomGnoteList.vue")
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
