<template>
  <ul class="navbar-nav d-flex align-items-center flex-wrap">
    <template v-for="(headerMenuItem, index) in HeaderMenuItems">
      <parent-menu-item
        :key="index"
        v-if="!headerMenuItem.submenu"
        :menu="headerMenuItem"
        :menuId="index"
      >
      </parent-menu-item>
      <template v-else>
        <parent-menu-group :key="index" :menu="headerMenuItem" :menuId="index">
        </parent-menu-group>
      </template>
    </template>
  </ul>
</template>

<script>
import common from "@/Common.vue";
import ParentMenuGroup from "@/components/ParentMenuGroup.vue";
import ParentMenuItem from "@/components/ParentMenuItem.vue";

export default {
  components: { ParentMenuGroup, ParentMenuItem },
  mixins: [common],
  data() {
    return {
      showBtn: false,
      HeaderMenu: "",
      HeaderMenuItems: [],
    };
  },
  methods: {
    changeColor() {
      console.log("changeColor called");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var selectbox1_source = "HeaderMenu";
      var selectbox1_destination = "HeaderMenuItems";
      var selectbox1_url = "api/jcom-web/user-menu";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss" scoped>
</style>