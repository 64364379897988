<template>
  <li class="has-subMenu">
    <a href="javascript:void(0)" class=""
      > <i :class="menu.MenuIcon" :style="menu.MenuStyle"></i> {{ menu.MenuText }}</a
    >
    <ul class="subMenu">
      <template v-for="(menuItem, index) in menu.submenu">
        <child-menu-item
          :key="index"
          v-if="!menuItem.submenu"
          :menu="menuItem"
          :menuId="index"
        >
        </child-menu-item>
      </template>
    </ul>
  </li>
</template>

<script>
import ChildMenuItem from "@/components/ChildMenuItem.vue";
export default {
  components: { ChildMenuItem },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>