<template>
  <div class="dropdown-custom">
    <a href="javascript:;" class="nav-item-toggle icon-active">
      <img class="svg" src="img/svg/alarm.svg" alt="img" />
    </a>
    <div class="dropdown-parent-wrapper">
      <div class="dropdown-wrapper">
        <h2 class="dropdown-wrapper__title">
          Notifications
          <span class="badge-circle badge-warning ms-1">4</span>
        </h2>
        <ul>
          <li
            class="nav-notification__single nav-notification__single--unread d-flex flex-wrap"
          >
            <div class="nav-notification__type nav-notification__type--primary">
              <img class="svg" src="img/svg/inbox.svg" alt="inbox" />
            </div>
            <div class="nav-notification__details">
              <p>
                <a
                  href=""
                  class="subject stretched-link text-truncate"
                  style="max-width: 180px"
                  >James</a
                >
                <span>sent you a message</span>
              </p>
              <p>
                <span class="time-posted">5 hours ago</span>
              </p>
            </div>
          </li>
          <li
            class="nav-notification__single nav-notification__single--unread d-flex flex-wrap"
          >
            <div
              class="nav-notification__type nav-notification__type--secondary"
            >
              <img class="svg" src="img/svg/upload.svg" alt="upload" />
            </div>
            <div class="nav-notification__details">
              <p>
                <a
                  href=""
                  class="subject stretched-link text-truncate"
                  style="max-width: 180px"
                  >James</a
                >
                <span>sent you a message</span>
              </p>
              <p>
                <span class="time-posted">5 hours ago</span>
              </p>
            </div>
          </li>
          <li
            class="nav-notification__single nav-notification__single--unread d-flex flex-wrap"
          >
            <div class="nav-notification__type nav-notification__type--success">
              <img class="svg" src="img/svg/log-in.svg" alt="log-in" />
            </div>
            <div class="nav-notification__details">
              <p>
                <a
                  href=""
                  class="subject stretched-link text-truncate"
                  style="max-width: 180px"
                  >James</a
                >
                <span>sent you a message</span>
              </p>
              <p>
                <span class="time-posted">5 hours ago</span>
              </p>
            </div>
          </li>
          <li
            class="nav-notification__single nav-notification__single d-flex flex-wrap"
          >
            <div class="nav-notification__type nav-notification__type--info">
              <img class="svg" src="img/svg/at-sign.svg" alt="at-sign" />
            </div>
            <div class="nav-notification__details">
              <p>
                <a
                  href=""
                  class="subject stretched-link text-truncate"
                  style="max-width: 180px"
                  >James</a
                >
                <span>sent you a message</span>
              </p>
              <p>
                <span class="time-posted">5 hours ago</span>
              </p>
            </div>
          </li>
          <li
            class="nav-notification__single nav-notification__single d-flex flex-wrap"
          >
            <div class="nav-notification__type nav-notification__type--danger">
              <img src="img/svg/heart.svg" alt="heart" class="svg" />
            </div>
            <div class="nav-notification__details">
              <p>
                <a
                  href=""
                  class="subject stretched-link text-truncate"
                  style="max-width: 180px"
                  >James</a
                >
                <span>sent you a message</span>
              </p>
              <p>
                <span class="time-posted">5 hours ago</span>
              </p>
            </div>
          </li>
        </ul>
        <a href="" class="dropdown-wrapper__more">See all incoming activity</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>