<template>
  <div></div>
</template>

<script>
import companyConfig from "@/company_config.json";

export default {
  methods: {
    logoutUser() {
      console.log("logoutUser called");
      localStorage.clear();
      //   this.$session.destroy();
      this.$cookies.remove("token");
      this.$cookies.remove("user");
      this.$router.push("/login");
    },
    getSelectedDate(date = "2024-08-31") {
      console.log("getSelectedDate called");
      console.log({ date });

      var CurrentDate = new Date().toISOString().slice(0, 10);
      console.log({ CurrentDate });

      var result = new Date(date);
      result.setDate(result.getDate() + 1);
      result = result.toISOString().slice(0, 10);
      console.log({ result });

      var selectedDate = date == CurrentDate ? CurrentDate : result;
      console.log({ selectedDate });

      return selectedDate;
    },
    getActiveStatusColor(status) {
      if (
        status == "Not completed" ||
        status == "Pending" ||
        status == "Process" ||
        status == "Returned" ||
        status == "Disabled" ||
        status == "Yet to complete" ||
        status == "Not eligible" ||
        status == "Suspended" ||
        status == "Waiting For Approval" ||
        status == "Waiting For Confirmation" ||
        status == "ZP Approval Pending" ||
        status == "NHQ Approval Pending"
      )
        return "warning";
      if (
        status == "Active" ||
        status == "Approved" ||
        status == "Used" ||
        status == "Enabled"
      )
        return "success";
      if (
        status == "Rejected" ||
        status == "Not scored" ||
        status == "Dissaffiliated" ||
        status == "Inactive" ||
        status == "Cancelled"
      )
        return "#ff0000";
      if (
        status == "Completed" ||
        status == "Closed" ||
        status == "Scored" ||
        status == "Provisional"
      )
        return "primary";
      return "dark";
    },
    sweetAlert(title, message, timer) {
      console.log("sweetAlert called");
      var icon = "";
      switch (title) {
        case "success":
          icon = "success";
          break;
        case "error":
          icon = "error";
          break;
        default:
          icon = "info";
          break;
      }
      console.log("icon=" + icon);
      var options = {
        dangerouslyHTMLString: true,
        autoClose: timer,
        type: title,
      };
      this.$toast(message, options);
    },
    getSelectBoxOptions(source, destination, condition, url) {
      console.log("getSelectBoxOptions function is called");

      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", condition=" +
          JSON.stringify(condition) +
          ", url=" +
          url
      );

      var server_url = companyConfig.apiURL;
      console.log("server_url=" + server_url);

      url = server_url + url;
      console.log("url=" + url);

      var token = this.$cookies.get("token");
      token = token == (null || undefined) ? "" : token;

      console.log("token=" + token);

      const thisIns = this;
      var output = "";
      var flag = 0;
      var records = [];
      var totalRecords = 0;

      var sourceValue = thisIns[source];
      console.log("sourceValue=" + sourceValue);

      var upload = {
        source: sourceValue,
        additional: condition,
      };
      console.log("upload=" + JSON.stringify(upload));

      this.$http({
        url: url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          output = response.data.output;
          flag = response.data.flag;
          totalRecords = records.length;
          console.log(
            "output=" +
              output +
              ", flag=" +
              flag +
              ", totalRecords=" +
              totalRecords
          );
          if (flag == 1) {
            records = response.data.records;
            thisIns[destination] = records;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    getPageDetails(destination, defaultValue, url, condition) {
      console.log("getPageDetails function is called");

      console.log(
        "destination=" +
          destination +
          ", url=" +
          url +
          ", condition=" +
          JSON.stringify(condition)
      );

      var server_url = companyConfig.apiURL;
      console.log("server_url=" + server_url);

      url = server_url + url;
      console.log("url=" + url);

      var token = this.$cookies.get("token");
      token = token == (null || undefined) ? "" : token;

      const thisIns = this;
      var output = "";
      var flag = 0;
      var records = defaultValue;

      this.$http({
        url: url,
        method: "POST",
        data: condition,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log({ flag }, { output }, { records });
          thisIns[destination] = records;
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns[destination] = records;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>