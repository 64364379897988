<template>
  <div class="dropdown-custom">
    <a href="javascript:;" class="nav-item-toggle"
      ><img src="img/flag.png" alt="" class="rounded-circle"
    /></a>
    <div class="dropdown-parent-wrapper">
      <div class="dropdown-wrapper dropdown-wrapper--small">
        <a href=""><img src="img/eng.png" alt="" /> English</a>
        <a href=""><img src="img/ger.png" alt="" /> German</a>
        <a href=""><img src="img/spa.png" alt="" /> Spanish</a>
        <a href=""><img src="img/tur.png" alt="" /> Turkish</a>
        <a href=""><img src="img/iraq.png" alt="" /> Arabic</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>